import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideNotification } from "store/notification/notification-actions";

export const useNotification = () => {
  const dispatch = useDispatch();
  const { title, message, type } = useSelector((state) => state.notification);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (message) {
      setShow(true);

      const timer = setTimeout(() => {
        dispatch(hideNotification());
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [message, dispatch]);

  const closeNotification = () => {
    dispatch(hideNotification());
  };

  return { show, title, message, type, closeNotification };
};
