import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Fragment, useState } from "react";
import { Link, useLocation } from "react-router-dom";

export default function NavbarExpItem(props) {
  const [isShowing, setIsShowing] = useState(false);
  const location = useLocation();
  const isActive = location.pathname === props.item.primary.href;

  return (
    <Popover
      className="relative"
      onMouseEnter={() => setIsShowing(true)}
      onMouseLeave={() => setIsShowing(false)}
    >
      <Popover.Button>
        <Link
          to={props.item.primary.href}
          className={`inline-flex items-center gap-x-1 text-sm font-semibold leading-6 ${isActive ? "text-red-600" : "text-gray-900"}`}
        >
          <span>{props.item.primary.name}</span>
          <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
        </Link>
      </Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
        show={isShowing}
        onMouseEnter={() => setIsShowing(true)}
        onMouseLeave={() => setIsShowing(false)}
      >
        <Popover.Panel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4">
          <div className="w-screen max-w-sm flex-auto rounded-3xl bg-white p-4 text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
            {props.item.children.map((item) => (
              <div
                key={item.name}
                className="relative rounded-lg p-4 hover:bg-gray-50"
              >
                <Link to={item.href} className="font-semibold text-gray-900">
                  {item.name}
                  <span className="absolute inset-0" />
                </Link>
                <p className="mt-1 text-gray-600">{item.description}</p>
              </div>
            ))}
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
