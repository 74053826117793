export default function Rodo() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 pt-6 sm:px-6 sm:pt-16 lg:px-8">
        <div className="mx-auto max-w-7xl sm:mt-0">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
            <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl">
              Klauzula Informacyjna – RODO
            </h2>
            <div className="mt-6 flex flex-col gap-x-0 gap-y-20 lg:flex-row">
              <div className="lg:w-full lg:max-w-4xl lg:flex-auto w-full">
                <p className="text-xl leading-8 text-gray-600">
                  Uprzejmie Informujemy, iż Administratorem Pani/a danych
                  osobowych jest RSystem sp zoo , Aleje Jerozolimskie 85/21,
                  02-001 Warszawa, NIP 7010574930, e-mail: biuro@r-system.pl
                  <br />W sprawach związanych z Pani/a danymi proszę kontaktować
                  się na adres e-mail biuro@r-system.pl
                </p>
                <div className="mt-10 max-w-4xl text-base leading-7 text-gray-700">
                  <ul className="list-decimal ml-6">
                    <li>
                      Dane będą przetwarzane w celu realizacji praw i obowiązków
                      wynikających ze współpracy .
                    </li>
                    <li>
                      Dane nie są udostępniane ani przekazywane podmiotom
                      trzecim.
                    </li>
                    <li>
                      Dane będą przechowywane bezterminowo, lub do czasu
                      wycofania zgody.
                    </li>
                    <li>
                      Ma Pan/i prawo dostępu do swoich danych osobowych, ich
                      sprostowania, usunięcia lub ograniczenia przetwarzania.
                    </li>
                    <li>
                      Ma Pan/i prawo do wniesienia sprzeciwu wobec dalszego
                      przetwarzania, a w przypadku wyrażenia zgody na
                      przetwarzanie danych do jej wycofania. Skorzystanie prawa
                      cofnięcia zgody nie ma wpływu na przetwarzanie, które
                      miało miejsce do momentu wycofania zgody.
                    </li>
                    <li>Ma Pan/i także prawo do przenoszenia danych.</li>
                    <li>
                      Przysługuje Pani/u prawo wniesienia skargi do organu
                      nadzorczego.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
