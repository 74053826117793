export default function Location() {
  return (
    <div className="py-24 sm:pt-24 sm:pb-0 sm:py-0 bg-white">
      <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-3">
        <div className="col-span-2 px-6 mx-auto max-w-3xl lg:mx-0 lg:px-8">
          <h2 className="text-2xl font-bold tracking-tight text-gray-900">
            Dane adresowe
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Zapraszamy do odwiedzenia naszej siedziby.
          </p>
        </div>
        <div className="w-full mx-auto mt-7 max-w-2xl text-base leading-7 lg:mx-0 lg:max-w-none lg:px-8">
          <div>
            <h3 className="border-l border-red-600 pl-6 font-semibold text-gray-900">
              Piaski
            </h3>
            <address className="border-l border-gray-200 pl-6 pt-2 not-italic text-gray-600">
              <p>ul. Piastowska 19</p>
              <p>09-200 Piaski</p>
            </address>
          </div>
        </div>
      </div>
    </div>
  );
}
