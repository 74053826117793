import { Link } from "react-router-dom";

export default function Examples(props) {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-16 sm:px-6 sm:py-24 lg:px-8">
        <div className="sm:flex sm:items-baseline sm:justify-between">
          <h2 className="text-2xl font-bold tracking-tight text-gray-900">
            {props.header}
          </h2>
          <Link
            to="/projekty"
            className="hidden text-sm font-semibold text-red-600 hover:text-red-500 sm:block"
          >
            Zobacz więcej
            <span aria-hidden="true"> &rarr;</span>
          </Link>
        </div>

        <div className="mt-6 grid grid-cols-1 gap-y-10 sm:grid-cols-3 sm:gap-x-6 sm:gap-y-6 lg:gap-x-8">
          {props.items.map((item) => (
            <div key={item.id} className="group relative">
              <div className="h-96 w-full overflow-hidden rounded-lg sm:aspect-h-3 sm:aspect-w-5 group-hover:opacity-75 sm:h-auto">
                <img
                  src={item.thumbnailSrc}
                  alt={item.imageAlt}
                  className="h-full w-full object-cover object-center"
                />
              </div>
              <h3 className="mt-4 text-base font-semibold text-gray-900">
                <Link to={item.href}>
                  <span className="absolute inset-0" />
                  {item.name}
                </Link>
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Powierzchnia:{" "}
                <span className="font-semibold text-gray-600">
                  {item.surface}m²
                </span>
              </p>
              <p className="mt-1 text-sm text-gray-500">
                Ilość pokoi:{" "}
                <span className="font-semibold text-gray-600">
                  {item.roomQuantity}
                </span>
              </p>
            </div>
          ))}
        </div>

        <div className="mt-6 sm:hidden">
          <Link
            to="/projekty"
            className="block text-sm font-semibold text-red-600 hover:text-red-500"
          >
            Zobacz więcej
            <span aria-hidden="true"> &rarr;</span>
          </Link>
        </div>
      </div>
    </div>
  );
}
