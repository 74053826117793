import ContactForm from "../../components/contact/ContactForm";
import Location from "../../components/location/Location";
import LocationMap from "../../components/location/LocationMap";

const Contact = () => {
  return (
    <div>
      <ContactForm />
      <Location />
      <LocationMap />
    </div>
  );
};

export default Contact;
