import IntroImages from "components/intro/IntroImages";
import { Link } from "react-router-dom";

export default function IntroContent() {
  return (
    <div className="overflow-hidden">
      <div className="mx-auto max-w-7xl px-6 pb-32 pt-36 sm:pt-60 lg:px-8 lg:pt-32">
        <div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
          <div className="relative w-full max-w-xl lg:shrink-0 xl:max-w-2xl">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
              Zrealizujemy Twoje marzenie o idealnym domu!
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600 sm:max-w-md lg:max-w-none">
              Stworzymy dla Ciebie przytulny i trwały dom. Spełnij swoje
              marzenie i zamieszkaj we własnym domu z ogrodem.
            </p>
            <div className="mt-10 flex items-center gap-x-6">
              <Link
                to="/projekty"
                className="rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                DARMOWA WYCENA
              </Link>
              <Link
                to="/o-nas"
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                Dowiedz się więcej <span aria-hidden="true">→</span>
              </Link>
            </div>
          </div>
          <IntroImages />
        </div>
      </div>
    </div>
  );
}
