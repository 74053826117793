import { Link, useParams } from "react-router-dom";
import { projects } from "utils/constants";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { showNotification } from "../../store/notification/notification-actions";
import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";

export default function Project() {
  const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

  const initialFormData = {
    firstName: "",
    email: "",
    phoneNumber: "",
    allowProcessingPersonalData: false,
  };

  const [expanded, setExpanded] = useState(false);

  const handleProcessingPersonalDataToggle = () => {
    setExpanded(!expanded);
  };

  const dispatch = useDispatch();
  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const params = new URLSearchParams({
        firstName: formData.firstName,
        email: formData.email,
        phoneNumber: formData.phoneNumber,
        allowProcessingPersonalData: formData.allowProcessingPersonalData,
        projectName: project.simplifiedName,
      });
      const response = await fetch(
        `${BACKEND_API_URL}/api/product/quotation?${params.toString()}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      if (!response.ok) {
        throw new Error(`Wystąpił błąd: ${response.statusText}`);
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const contentDisposition = response.headers.get("content-disposition");
      let fileName = `${project.simplifiedName}`;
      if (contentDisposition && contentDisposition.includes("attachment")) {
        const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
        if (fileNameMatch.length === 2) {
          fileName += fileNameMatch[1];
        }
      }

      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      a.remove();

      setFormData(initialFormData);
      dispatch(
        showNotification(
          "Wycena pobrana!",
          `Wycena projektu ${project.name} została pobrana.`,
          "success",
        ),
      );
    } catch (error) {
      console.log(error);
      dispatch(
        showNotification(
          "Napotkaliśmy problem.",
          "Wystąpił błąd podczas wysyłania wiadomości. Spróbuj ponownie później.",
          "error",
        ),
      );
    }
  };

  const { name } = useParams();
  const project = projects.find((p) => p.simplifiedName === name);

  if (!project) {
    return <div>Project not found</div>;
  }

  return (
    <>
      <div className="relative isolate bg-white">
        <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
          <div className="relative px-6 pb-20 pt-24 sm:pt-32 lg:static lg:px-8 lg:py-48">
            <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
              <div className="absolute inset-y-0 left-0 -z-10 w-full overflow-hidden bg-gray-100 lg:w-1/2">
                <img
                  src={project.imageSrc}
                  className="w-full object-cover min-h-0 h-full"
                  alt={project.name}
                />
                <div className="absolute inset-0 bg-gradient-to-b from-white/70 to-white/100 transition-opacity duration-500"></div>
                <svg
                  className="absolute inset-0 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                  aria-hidden="true"
                >
                  <defs>
                    <pattern
                      id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                      width={200}
                      height={200}
                      x="100%"
                      y={-1}
                      patternUnits="userSpaceOnUse"
                    >
                      <path d="M130 200V.5M.5 .5H200" fill="none" />
                    </pattern>
                  </defs>
                  <rect
                    width="100%"
                    height="100%"
                    strokeWidth={0}
                    fill="white"
                  />
                  <rect
                    width="100%"
                    height="100%"
                    strokeWidth={0}
                    fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)"
                  />
                </svg>
              </div>
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Pobierz darmową wycenę
                <br />
                projektu {project.name}
              </h2>
              <p className="mt-6 text-base leading-8 text-gray-600">
                Wypełnij formularz i odbierz bezpłatną wycenę budowy tego
                projektu.
              </p>
              <dl className="mt-10 space-y-4 text-base leading-7 text-gray-600">
                <div className="flex gap-x-4">
                  <dt className="flex-none">
                    <span className="sr-only">Telephone</span>
                    <PhoneIcon
                      className="h-7 w-6 text-gray-400"
                      aria-hidden="true"
                    />
                  </dt>
                  <dd>
                    <Link className="hover:text-gray-900" to="tel:+48533354398">
                      (+48) 533-354-398
                    </Link>
                  </dd>
                </div>
                <div className="flex gap-x-4">
                  <dt className="flex-none">
                    <span className="sr-only">Email</span>
                    <EnvelopeIcon
                      className="h-7 w-6 text-gray-400"
                      aria-hidden="true"
                    />
                  </dt>
                  <dd>
                    <Link
                      className="hover:text-gray-900"
                      to="mailto:hello@example.com"
                    >
                      domyrenomy@gmail.com
                    </Link>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
          <form
            id="contact-form"
            onSubmit={handleSubmit}
            className="px-6 pb-24 pt-0 sm:pb-32 lg:px-8 lg:py-48"
          >
            <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
              <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                <div className="sm:col-span-2">
                  <label
                    htmlFor="firstName"
                    className="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    Imię
                  </label>
                  <div className="mt-2.5">
                    <input
                      type="text"
                      name="firstName"
                      id="firstName"
                      autoComplete="given-name"
                      required={true}
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
                      value={formData.firstName}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="email"
                    className="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    Email
                  </label>
                  <div className="mt-2.5">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      autoComplete="email"
                      required={true}
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="phoneNumber"
                    className="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    Numer telefonu
                  </label>
                  <div className="mt-2.5">
                    <input
                      type="number"
                      name="phoneNumber"
                      id="phoneNumber"
                      autoComplete="tel"
                      required={true}
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <fieldset>
                    <legend className="sr-only">Przetwarzanie danych</legend>
                    <div className="space-y-5">
                      <div className="relative flex items-start">
                        <div className="flex h-6 items-center">
                          <input
                            id="allowProcessingPersonalData"
                            aria-describedby="allowProcessingPersonalData-description"
                            name="allowProcessingPersonalData"
                            type="checkbox"
                            required={true}
                            className="h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-red-600"
                            checked={formData.allowProcessingPersonalData}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="ml-3 text-sm leading-6">
                          <label
                            htmlFor="allowProcessingPersonalData"
                            className="font-medium text-gray-900"
                          >
                            Przetwarzanie danych osobowych
                          </label>
                          <p
                            id="allowProcessingPersonalData-description"
                            className={`text-xs text-gray-500 description ${expanded ? "expanded" : "collapsed"}`}
                          >
                            Wyrażam zgodę na przetwarzanie moich danych
                            osobowych poprzez RSystem SP. Z O.O. zgodnie z
                            Rozporządzeniem Parlamentu Europejskiego I Rady (UE)
                            2016/679 z dnia 27 kwietnia 2016r. w sprawie ochrony
                            osób fizycznych w związku z przetwarzaniem danych
                            osobowych i w sprawie swobodnego przepływu takich
                            danych oraz uchylenia dyrektywy 95/46/WE (ogólne
                            rozporządzenie o ochronie danych) oraz zapoznałem/am
                            się z informacjami dotyczącymi przetwarzania danych.
                          </p>
                          <button
                            type="button"
                            onClick={handleProcessingPersonalDataToggle}
                            className="text-red-600 hover:underline"
                          >
                            {expanded ? "Pokaż mniej" : "Pokaż więcej"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
              <div className="mt-8 flex justify-end">
                <button
                  type="submit"
                  className="rounded-md bg-red-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                >
                  Pobierz wycenę
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
