import frameHouse from "assets/images/technologies/wooden/frame-house.jpg";
import cltHouse from "assets/images/technologies/wooden/clt-house.jpg";
import modularHouse from "assets/images/technologies/wooden/modular-house.jpg";
import Features from "../../../components/features/Features";

const types = [
  {
    name: "Domy szkieletowe",
    description:
      "Domy szkieletowe, znane również jako kanadyjskie, są wznoszone na bazie drewnianego szkieletu, który wypełnia się materiałem izolacyjnym i obudowuje płytami drewnopochodnymi, takimi jak płyty OSB lub płyty Fermacell. Taka konstrukcja jest lekka, ale niezwykle wytrzymała. Domy te wyróżniają się szybkim czasem budowy oraz wysoką efektywnością energetyczną.",
    imageSrc: frameHouse,
    imageAlt: "Placeholder image.",
  },
  {
    name: "Domy modułowe",
    description:
      "Domy modułowe to nowoczesna metoda budowy, gdzie poszczególne moduły są prefabrykowane w fabryce, a następnie transportowane na plac budowy i tam montowane. Prefabrykacja umożliwia precyzyjną kontrolę jakości oraz znacząco skraca czas budowy.",
    imageSrc: modularHouse,
    imageAlt: "Placeholder image.",
  },
  {
    name: "Domy z klejonego drewna warstwowego (CLT - Cross-Laminated Timber)",
    description:
      "CLT to innowacyjna technologia, w której drewniane deski są sklejane warstwowo, tworząc bardzo wytrzymałe i stabilne panele. Panele CLT są wykorzystywane do budowy ścian, podłóg i dachów, a ich wytrzymałość pozwala na wznoszenie wielokondygnacyjnych budynków.",
    imageSrc: cltHouse,
    imageAlt: "Placeholder image.",
  },
];

const features = [
  {
    name: "Ekologia",
    description:
      "Drewno jest odnawialnym surowcem, a budowa z drewna ma mniejszy ślad węglowy niż budowa z betonu czy stali.",
  },
  {
    name: "Energooszczędność",
    description:
      "Drewno ma doskonałe właściwości izolacyjne, co przekłada się na niższe koszty ogrzewania.",
  },
  {
    name: "Zdrowy mikroklimat",
    description:
      "Drewniane domy 'oddychają', co poprawia jakość powietrza wewnątrz budynku.",
  },
  {
    name: "Szybkość budowy",
    description:
      "Prefabrykowane elementy i moduły pozwalają na szybkie wznoszenie budynków.",
  },
  {
    name: "Estetyka",
    description:
      "Naturalne piękno drewna nadaje budynkom unikalny charakter i ciepły wygląd.",
  },
];

const WoodenTechnologyView = () => {
  return (
    <>
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 pt-6 sm:px-6 sm:pt-14 lg:px-8">
          <div className="mx-auto max-w-2xl lg:max-w-none">
            <div className="max-w-3xl">
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Technologia drewniana
              </p>
              <p className="mt-4 text-gray-500">
                Budowa domu z drewna to technologia o bogatej historii, która
                cieszy się dużą popularnością w wielu częściach świata, w tym w
                krajach skandynawskich, Kanadzie, Stanach Zjednoczonych oraz
                Polsce. Drewno jako materiał budowlany ma wiele zalet, takich
                jak trwałość, doskonałe właściwości izolacyjne, ekologiczność
                oraz estetyczny wygląd.
              </p>
              <p className="mt-4 text-gray-500">
                Budowa domu z drewna może być doskonałym wyborem dla osób
                ceniących ekologiczne rozwiązania, estetykę i zdrowy mikroklimat
                wnętrza. Wybór konkretnej technologii zależy od indywidualnych
                potrzeb, budżetu oraz lokalnych warunków klimatycznych.
              </p>
            </div>

            <div className="mt-10 space-y-16 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-16">
              {types.map((type) => (
                <div
                  key={type.name}
                  className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center lg:gap-x-8"
                >
                  <div className="mt-6 lg:col-span-5 lg:mt-0 xl:col-span-4">
                    <h3 className="text-lg font-medium text-gray-900">
                      {type.name}
                    </h3>
                    <p className="mt-2 text-sm text-gray-500">
                      {type.description}
                    </p>
                  </div>
                  <div className="flex-auto lg:col-span-7 xl:col-span-8">
                    <div className="aspect-h-2 aspect-w-5 overflow-hidden rounded-lg bg-gray-100">
                      <img
                        src={type.imageSrc}
                        alt={type.imageAlt}
                        className="object-cover object-center"
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Features
        features={features}
        tag="Kluczowe punkty"
        header="Zalety budowy domu w technologii drewnianej"
        description=""
      />
    </>
  );
};

export default WoodenTechnologyView;
