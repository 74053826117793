import React from "react";
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";

export default function LocationMap() {
  const position = { lat: 52.83201146313121, lng: 19.644329291540494 };
  const key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  return (
    <div className="bg-white pt-0 sm:pt-12 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-1">
          <div className="bg-red-600 h-96 w-full rounded-lg overflow-hidden">
            <APIProvider apiKey={key}>
              <Map
                defaultCenter={position}
                defaultZoom={17}
                gestureHandling={"greedy"}
                disableDefaultUI={true}
                style={{ width: "100%", height: "100%" }} // Adjusted height to fill the parent container
              >
                <Marker position={position} />
              </Map>
            </APIProvider>
          </div>
        </div>
      </div>
    </div>
  );
}
