import { Link, useLocation } from "react-router-dom";

export default function NavbarItem(props) {
  const location = useLocation();
  const isActive = location.pathname === props.item.href;

  return (
    <Link
      to={props.item.href}
      className={`text-sm font-semibold leading-6 ${isActive ? "text-red-600" : "text-gray-900"}`}
    >
      {props.item.name}
    </Link>
  );
}
