import {
  BuildingOffice2Icon,
  ChatBubbleOvalLeftEllipsisIcon,
  HomeIcon,
} from "@heroicons/react/24/outline";

const services = [
  {
    name: "Budowa domów jednorodzinnych i wielorodzinnych",
    description:
      "Realizujemy kompleksowe projekty budowy domów jednorodzinnych oraz wielorodzinnych. " +
      "Każdy etap prac jest dokładnie monitorowany, aby zapewnić najwyższą jakość wykonania " +
      "i pełne zadowolenie naszych klientów. Korzystamy z najlepszych materiałów i nowoczesnych technologii, " +
      "aby tworzyć przestrzenie, które są funkcjonalne, estetyczne i trwałe.",
    icon: HomeIcon,
  },
  {
    name: "Budowa obiektów komercyjnych i przemysłowych",
    description:
      "Zajmujemy się budową nowoczesnych obiektów komercyjnych i przemysłowych. " +
      "Nasze doświadczenie i wiedza pozwalają nam realizować projekty, " +
      "które spełniają najwyższe standardy branżowe. Dbamy o to, " +
      "aby każdy obiekt był dostosowany do specyficznych potrzeb naszych klientów, " +
      "łącząc funkcjonalność z estetyką.",
    icon: BuildingOffice2Icon,
  },
  {
    name: "Projektowanie i doradztwo techniczne",
    description:
      "Oferujemy profesjonalne usługi projektowe oraz doradztwo techniczne. " +
      "Nasz zespół pomoże w optymalizacji Twojego projektu, doradzając najlepsze rozwiązania i technologie. " +
      "Nasze podejście gwarantuje, że każdy projekt będzie realizowany zgodnie z najwyższymi standardami jakości.",
    icon: ChatBubbleOvalLeftEllipsisIcon,
  },
];

export default function Services() {
  return (
    <div className="bg-white mx-auto max-w-7xl px-6 py-16 sm:px-6 sm:py-24 lg:px-8">
      <div className="mx-auto max-w-7xl">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-2xl font-bold tracking-tight text-gray-900">
            Nasze usługi
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Oferujemy szeroki zakres usług budowlanych, dostosowanych do potrzeb
            zarówno klientów indywidualnych, jak i biznesowych. Nasza firma
            specjalizuje się w realizacji projektów na najwyższym poziomie,
            dbając o każdy detal. Poniżej przedstawiamy nasze główne obszary
            działalności:
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {services.map((feature) => (
              <div key={feature.name} className="flex flex-col">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-red-600">
                    <feature.icon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600">
                  <p className="flex-auto">{feature.description}</p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
