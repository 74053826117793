import { CheckIcon } from "@heroicons/react/24/outline";
import image from "assets/images/about/featureImage.jpg";

const features = [
  {
    name: "Doświadczenie.",
    description:
      "Mamy za sobą wiele zrealizowanych projektów, które są najlepszym dowodem naszej kompetencji.",
    icon: CheckIcon,
  },
  {
    name: "Indywidualne podejście.",
    description:
      "Każdy projekt traktujemy indywidualnie, dostosowując się do potrzeb i oczekiwań naszych klientów.",
    icon: CheckIcon,
  },
  {
    name: "Terminowość.",
    description:
      "Dbamy o to, aby nasze projekty były realizowane zgodnie z ustalonym harmonogramem.",
    icon: CheckIcon,
  },
  {
    name: "Gwarancja jakości.",
    description:
      "Każdy etap prac jest przez nas dokładnie monitorowany, co pozwala nam na zapewnienie najwyższej jakości wykonania.",
    icon: CheckIcon,
  },
];

export default function Features() {
  return (
    <div className="overflow-hidden bg-white">
      <div className="mx-auto max-w-7xl px-6 py-16 sm:px-6 sm:py-24 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-red-600">
                Poznaj nas lepiej
              </h2>
              <p className="mt-2 text-2xl font-bold tracking-tight text-gray-900">
                Dlaczego warto nam zaufać?
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Od lat z sukcesem realizujemy projekty budowlane, dbając o
                najwyższą jakość i pełne zadowolenie naszych klientów. Nasza
                firma to synonim profesjonalizmu i rzetelności w branży
                budowlanej. Poznaj powody, dla których warto nam zaufać:
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon
                        className="absolute left-1 top-1 h-5 w-5 text-red-600"
                        aria-hidden="true"
                      />
                      {feature.name}
                    </dt>{" "}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <img
            src={image}
            alt="Product screenshot"
            className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
            width={2432}
            height={1442}
          />
        </div>
      </div>
    </div>
  );
}
