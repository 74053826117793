const values = [
  {
    name: "Jakość",
    description:
      "Stawiamy na najwyższe standardy wykonania. Korzystamy z najlepszych materiałów i nowoczesnych technologii, aby nasze projekty były solidne i trwałe.",
  },
  {
    name: "Profesjonalizm",
    description:
      "Nasz zespół to wykwalifikowani specjaliści z wieloletnim doświadczeniem. Pracujemy zgodnie z najwyższymi standardami branżowymi.",
  },
  {
    name: "Zaufanie",
    description:
      "Budujemy relacje z klientami oparte na uczciwości, transparentności i wzajemnym szacunku.",
  },
  {
    name: "Innowacyjność",
    description:
      "Śledzimy najnowsze trendy w budownictwie i wprowadzamy innowacyjne rozwiązania, które podnoszą komfort i funkcjonalność naszych realizacji.",
  },
];

export default function Values() {
  return (
    <div className="mx-auto max-w-7xl px-6 py-16 sm:px-6 sm:py-24 lg:px-8">
      <div className="mx-auto max-w-2xl lg:mx-0">
        <h2 className="text-2xl font-bold tracking-tight text-gray-900">
          Nasze wartości
        </h2>
        <p className="mt-6 text-lg leading-8 text-gray-600">
          Z pasją i zaangażowaniem realizujemy projekty budowlane, dążąc do
          spełnienia oczekiwań naszych klientów. Każdy nasz projekt jest
          odzwierciedleniem naszych wartości: jakości, profesjonalizmu, zaufania
          i innowacyjności. Dzięki nim budujemy solidne fundamenty dla
          przyszłości.
        </p>
      </div>
      <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 text-base leading-7 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
        {values.map((value) => (
          <div key={value.name}>
            <dt className="font-semibold text-gray-900">{value.name}</dt>
            <dd className="mt-1 text-gray-600">{value.description}</dd>
          </div>
        ))}
      </dl>
    </div>
  );
}
