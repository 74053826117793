import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";

const faqs = [
  {
    question: "Czy mogę wybudować dom z dowolnego projektu?",
    answer: "Tak, możesz wybudować u nas dom z dowolnego projektu. ",
  },
  {
    question: "Czy pomagają Państwo z formalnościami do budowy?",
    answer:
      "Tak, oczywiście. Podczas podpisania umowy możesz udzielić nam upoważnienia, " +
      "dzięki czemu będziemy mogli komunikować się z urzędami w Twoim imieniu oraz złożyć " +
      "wszystkie niezbędne dokumenty wymagane do rozpoczęcia budowy.",
  },
  {
    question: "Ile kosztuje projekt budowlany?",
    answer:
      "U nas nie ponosisz kosztów związanych z projektem. " +
      "Wstępny kosztorys zawiera opracowanie i adaptację projektu zgodnie z Twoimi potrzebami. " +
      "Przed rozpoczęciem współpracy szczegółowo omawiamy wstępny kosztorys, " +
      "aby wszystko było jasne i przejrzyste.",
  },
  {
    question: "Czy sprzedają Państwo działki?",
    answer:
      "Nie, nasza firma zajmuje się wyłącznie pracami budowlanymi. " +
      "Nie prowadzimy działalności deweloperskiej i nie sprzedajemy działek.",
  },
  {
    question: "Czy budowę można kredytować?",
    answer:
      "Tak, istnieje taka możliwość. Możesz skorzystać z usług naszego doradcy kredytowego " +
      "lub współpracować z wybranym przez siebie bankiem lub doradcą.",
  },
];

export default function Faqs() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-16 sm:px-6 sm:py-24 lg:px-8">
        <div className="mx-auto max-w-7xl divide-y divide-gray-900/10">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
            Najczęstsze pytania
          </h2>
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <DisclosureButton className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">
                          {faq.question}
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmallIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          ) : (
                            <PlusSmallIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          )}
                        </span>
                      </DisclosureButton>
                    </dt>
                    <DisclosurePanel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-600">
                        {faq.answer}
                      </p>
                    </DisclosurePanel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
