import IntroContent from "./IntroContent";
import IntroGradient from "./IntroGradient";
import IntroLines from "./IntroLines";

export default function Intro() {
  return (
    <main>
      <div className="relative isolate">
        <IntroLines />
        <IntroGradient />
        <IntroContent />
      </div>
    </main>
  );
}
