import ProjectList from "components/projects/ProjectList";
import { projects } from "utils/constants";

const listHeader = "Projekty Twojego domu z darmową wyceną!";
const listSubheader =
  "Przygotowaliśmy dla Ciebie kilka interesujących projektów z gotowymi wycenami do pobrania." +
  " Wybierz projekt poniżej i kliknij “Pobierz wycenę”, aby otrzymać bezpłatną wycenę.";

const ProjectListView = () => {
  return (
    <ProjectList
      header={listHeader}
      subheader={listSubheader}
      items={projects}
    />
  );
};

export default ProjectListView;
