const initialState = {
  title: null,
  message: null,
  type: "success",
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SHOW_NOTIFICATION":
      return {
        ...state,
        title: action.payload.title,
        message: action.payload.message,
        type: action.payload.type,
      };
    case "HIDE_NOTIFICATION":
      return {
        ...state,
        title: null,
        message: null,
        type: "success",
      };
    default:
      return state;
  }
};

export default notificationReducer;
