import cyklamenBetaThumbnail from "assets/images/projects/cyklamen-beta/cyklamen-beta-thumbnail.jpg";
import kordianStoThumbnail from "assets/images/projects/kordian-sto/kordian-sto-thumbnail.jpg";
import kubusG1Thumbnail from "assets/images/projects/kubus-g1/kubus-g1-thumbnail.jpg";
import kwadraBoboThumbnail from "assets/images/projects/kwadra-bobo/kwadra-bobo-thumbnail.jpg";
import antekThumbnail from "assets/images/projects/antek/antek-thumbnail.jpg";
import gucioThumbnail from "assets/images/projects/gucio/gucio-thumbnail.jpg";
import maluchThumbnail from "assets/images/projects/maluch/maluch-thumbnail.jpg";

import cyklamenBeta from "assets/images/projects/cyklamen-beta/cyklamen-beta.jpg";
import kordiaSto from "assets/images/projects/kordian-sto/kordian-sto.jpg";
import kubusG1 from "assets/images/projects/kubus-g1/kubus-g1.jpg";
import kwadraBobo from "assets/images/projects/kwadra-bobo/kwadra-bobo.jpg";
import antek from "assets/images/projects/antek/antek.jpg";
import gucio from "assets/images/projects/gucio/gucio.jpg";
import maluch from "assets/images/projects/maluch/maluch.jpg";

export const projectsData = [
  {
    id: 1,
    simplifiedName: "kordian-sto",
    name: "Kordian Sto",
    surface: "99.8",
    roomQuantity: "5",
    thumbnailSrc: kordianStoThumbnail,
    imageSrc: kordiaSto,
    imageAlt: "",
  },
  {
    id: 2,
    simplifiedName: "cyklamen-beta",
    name: "Cyklamen Beta",
    surface: "99.2",
    roomQuantity: "5",
    thumbnailSrc: cyklamenBetaThumbnail,
    imageSrc: cyklamenBeta,
    imageAlt: "",
  },
  {
    id: 3,
    simplifiedName: "kubus-g1",
    name: "Kubuś G1",
    surface: "76.1",
    roomQuantity: "4",
    thumbnailSrc: kubusG1Thumbnail,
    imageSrc: kubusG1,
    imageAlt: "",
  },
  {
    id: 4,
    simplifiedName: "kwadra-bobo",
    name: "Kwadra Bobo",
    surface: "94.2",
    roomQuantity: "4",
    thumbnailSrc: kwadraBoboThumbnail,
    imageSrc: kwadraBobo,
    imageAlt: "",
  },
  {
    id: 5,
    simplifiedName: "antek",
    name: "Antek",
    surface: "63.6",
    roomQuantity: "3",
    thumbnailSrc: antekThumbnail,
    imageSrc: antek,
    imageAlt: "",
  },
  {
    id: 6,
    simplifiedName: "gucio",
    name: "Gucio",
    surface: "46.1",
    roomQuantity: "2",
    thumbnailSrc: gucioThumbnail,
    imageSrc: gucio,
    imageAlt: "",
  },
  {
    id: 7,
    simplifiedName: "maluch",
    name: "Maluch",
    surface: "37.9",
    roomQuantity: "1",
    thumbnailSrc: maluchThumbnail,
    imageSrc: maluch,
    imageAlt: "",
  },
];

export const projects = projectsData.map((project) => ({
  ...project,
  href: `/projekt/${project.simplifiedName}`,
}));

export const examples = projects.slice(0, 3);
