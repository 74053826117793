import Reviews from "components/reviews/Reviews";
import ContactForm from "components/contact/ContactForm";
import Location from "components/location/Location";
import LocationMap from "components/location/LocationMap";
import Mission from "components/about/mission/Mission";
import Services from "components/about/services/Services";
import Values from "components/about/values/Values";
import Features from "components/about/features/Features";
import Introduction from "components/about/introduction/Introduction";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <>
      <Introduction />
      <Mission />
      <Services />
      {/*<div className="bg-red-100">*/}
      {/*    <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:justify-between lg:px-8">*/}
      {/*        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">*/}
      {/*            Masz pytania?*/}
      {/*            <br/>*/}
      {/*            Skontaktuj się z nami*/}
      {/*        </h2>*/}
      {/*        <div className="mt-10 flex items-center gap-x-6 lg:mt-0 lg:flex-shrink-0">*/}
      {/*            <Link*/}
      {/*                to="/kontakt"*/}
      {/*                className="rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"*/}
      {/*            >*/}
      {/*                Porozmawiaj*/}
      {/*            </Link>*/}
      {/*            <a href="#" className="text-sm font-semibold leading-6 text-gray-900">*/}
      {/*                Learn more <span aria-hidden="true">→</span>*/}
      {/*            </a>*/}
      {/*        </div>*/}
      {/*    </div>*/}
      {/*</div>*/}
      <Values />
      <Features />
      <Reviews />
      <ContactForm />
      <Location />
      <LocationMap />
    </>
  );
};

export default About;
