const stats = [
  { label: "Lat doświadczenia", value: "20" },
  { label: "Wykonanych projektów", value: "1000+" },
  { label: "Na wybudowanie domu marzeń", value: "3 miesiące" },
];

export default function Mission() {
  return (
    <div className="mx-auto max-w-7xl px-6 py-16 sm:px-6 sm:py-24 lg:px-8">
      <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
        <h2 className="text-2xl font-bold tracking-tight text-gray-900">
          Nasza misja
        </h2>
        <div className="mt-6 flex flex-col gap-x-8 gap-y-20 lg:flex-row">
          <div className="lg:w-full lg:max-w-2xl lg:flex-auto">
            <p className="text-xl leading-8 text-gray-600">
              Naszą misją jest dostarczanie kompleksowych usług budowlanych,
              które spełniają oczekiwania naszych klientów w zakresie jakości,
              terminowości i innowacyjności. Dążymy do tworzenia przestrzeni,
              które są funkcjonalne, estetyczne i trwałe.
            </p>
            <div className="mt-10 max-w-xl text-base leading-7 text-gray-700">
              <p>
                Każdy projekt traktujemy indywidualnie, dostosowując nasze
                usługi do specyficznych potrzeb i oczekiwań klienta. Dbamy o to,
                aby każdy etap prac był dostosowany do Twoich wymagań.
              </p>
              <p className="mt-10">
                Jesteśmy firmą, która kładzie duży nacisk na transparentność
                działań. Każdy klient jest na bieżąco informowany o postępach
                prac, a wszelkie decyzje podejmujemy wspólnie.
              </p>
            </div>
          </div>
          <div className="lg:flex lg:flex-auto lg:justify-center">
            <dl className="w-64 space-y-8 xl:w-80">
              {stats.map((stat) => (
                <div key={stat.label} className="flex flex-col-reverse gap-y-4">
                  <dt className="text-base leading-7 text-gray-600">
                    {stat.label}
                  </dt>
                  <dd className="text-5xl font-semibold tracking-tight text-gray-900">
                    {stat.value}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}
