import { Route, Routes } from "react-router-dom";
import WoodenTechnologyView from "./wooden/WoodenTechnologyView";
import BrickTechnologyView from "./brick/BrickTechnologyView";

const TechnologyRoutes = () => {
  return (
    <Routes>
      <Route path="drewniana" element={<WoodenTechnologyView />} />
      <Route path="murowana" element={<BrickTechnologyView />} />
    </Routes>
  );
};

export default TechnologyRoutes;
