import ProjectPortfolio from "components/projects/portfolio/ProjectPortfolio";
import portfolio1 from "assets/images/projects/portfolio/portfolio-1.jpg";
import portfolio2 from "assets/images/projects/portfolio/portfolio-2.jpg";
import portfolio3 from "assets/images/projects/portfolio/portfolio-3.jpg";
import portfolio4 from "assets/images/projects/portfolio/portfolio-4.jpg";
import portfolio5 from "assets/images/projects/portfolio/portfolio-5.jpg";
import portfolio6 from "assets/images/projects/portfolio/portfolio-6.jpg";

const listHeader = "Realizacje";
const listSubheader =
  "Odkryj inspirujące projekty budowlane.";

const images = [
  { key: 6, url: portfolio6 },
  { key: 3, url: portfolio3 },
  { key: 1, url: portfolio1 },
  { key: 5, url: portfolio5 },
  { key: 2, url: portfolio2 },
  { key: 4, url: portfolio4 },
];

const ProjectPortfolioView = () => {
  return <ProjectPortfolio header={listHeader} subheader={listSubheader} images={images} />;
};

export default ProjectPortfolioView;
