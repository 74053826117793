import { Link, useLocation } from "react-router-dom";
import { projectsData } from "utils/constants";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function ProjectList(props) {
  const query = useQuery();
  const surfaceMin = query.get("surfaceMin");
  const surfaceMax = query.get("surfaceMax");

  const minSurface = surfaceMin ? parseFloat(surfaceMin) : 0;
  const maxSurface = surfaceMax ? parseFloat(surfaceMax) : Infinity;

  const filteredProjects = projectsData
    .filter(
      (project) =>
        project.surface >= minSurface && project.surface <= maxSurface,
    )
    .sort((a, b) => b.surface - a.surface) // Sort in descending order
    .map((project) => ({
      ...project,
      href: `/projekt/${project.simplifiedName}`,
    }));

  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 pt-6 sm:px-6 sm:pt-14 lg:px-8">
        <div>
          <h2 className="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl">
            {props.header}
          </h2>
          <p className="mt-6 text-base leading-7 text-gray-600">
            {props.subheader}
          </p>
        </div>

        <div className="mt-6 grid grid-cols-1 gap-y-10 sm:grid-cols-3 sm:gap-x-6 sm:gap-y-6 lg:gap-x-8">
          {filteredProjects.map((item) => (
            <div key={item.id} className="group relative">
              <div className="h-96 w-full overflow-hidden rounded-lg sm:aspect-h-3 sm:aspect-w-5 group-hover:opacity-75 sm:h-auto">
                <img
                  src={item.thumbnailSrc}
                  alt={item.imageAlt}
                  className="h-full w-full object-cover object-center"
                />
              </div>
              <h3 className="mt-4 text-base font-semibold text-gray-900">
                <Link to={item.href}>
                  <span className="absolute inset-0" />
                  {item.name}
                </Link>
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Powierzchnia:{" "}
                <span className="font-semibold text-gray-600">
                  {item.surface}m²
                </span>
              </p>
              <p className="mt-1 text-sm text-gray-500">
                Ilość pokoi:{" "}
                <span className="font-semibold text-gray-600">
                  {item.roomQuantity}
                </span>
              </p>
            </div>
          ))}
        </div>

        <div className="mt-6 sm:hidden">
          <Link
            to="#"
            className="block text-sm font-semibold text-red-600 hover:text-red-500"
          >
            Zobacz więcej
            <span aria-hidden="true"> &rarr;</span>
          </Link>
        </div>
      </div>
    </div>
  );
}
