import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Home from "views/home/Home";
import "App.css";
import Contact from "views/contact/Contact";
import Navbar from "components/navbar/Navbar";
import Footer from "components/footer/Footer";
import About from "views/about/About";
import Notification from "components/notification/Notification";
import { Provider } from "react-redux";
import store from "store/store";
import ScrollToTop from "utils/helpers";
import Project from "components/projects/Project";
import Rodo from "components/about/rodo/Rodo";
import NotFound from "./views/404/NotFound";
import TechnologyRoutes from "./views/technology/TechnologyRoutes";
import ProjectPortfolioView from "./views/project/portfolio/ProjectPortfolioView";
import ProjectListView from "./views/project/list/ProjectListView";

function AppContent() {
  const location = useLocation();
  const isNotFoundPage = location.pathname === "/404";

  return (
    <>
      {!isNotFoundPage && <Navbar />}
      <Notification />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/technologie/*" element={<TechnologyRoutes />} />
        <Route path="/projekty" element={<ProjectListView />} />
        <Route path="/projekt/:name" element={<Project />} />
        <Route path="/realizacje" element={<ProjectPortfolioView />} />
        <Route path="/o-nas" element={<About />} />
        <Route path="/rodo" element={<Rodo />} />
        <Route path="/kontakt" element={<Contact />} />
        <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
      {!isNotFoundPage && <Footer />}
    </>
  );
}

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Provider store={store}>
        <AppContent />
      </Provider>
    </Router>
  );
}

export default App;
