export const showNotification = (title, message, type = "success") => {
  return {
    type: "SHOW_NOTIFICATION",
    payload: { title, message, type },
  };
};

export const hideNotification = () => {
  return {
    type: "HIDE_NOTIFICATION",
  };
};
