import Intro from "components/intro/Intro";
import Examples from "components/projects/Examples";
import Faqs from "components/faqs/Faqs";
import Features from "components/features/Features";
import Location from "components/location/Location";
import ContactForm from "components/contact/ContactForm";
import LocationMap from "components/location/LocationMap";
import { examples } from "utils/constants";

const examplesHeader = "Nasze projekty";

const features = [
  {
    name: "Indywidualny projekt",
    description:
      "Stworzymy dla Ciebie spersonalizowany projekt, uwzględniający wszystkie Twoje potrzeby i wymagania. Dzięki temu Twój nowy dom będzie doskonały.",
  },
  {
    name: "Dokumentacja",
    description:
      "Oferujemy wsparcie w kompletowaniu dokumentacji potrzebnej do uzyskania pozwolenia na budowę, co pozwoli zaoszczędzić Państwa cenny czas.",
  },
  {
    name: "Architekt",
    description:
      "Przez cały proces projektowania i budowy domu zapewniamy wsparcie architekta.",
  },
  {
    name: "Kredyt",
    description: "Udzielamy pomocy w uzyskaniu kredytu na budowę domu.",
  },
  {
    name: "Kierownik budowy",
    description:
      "Zapewniamy kompleksowy nadzór kierownika budowy na każdym etapie realizacji projektów realizowanych przez naszą firmę.",
  },
  {
    name: "Gwarancja",
    description: "Zapewniamy gwarancję wykonawczą.",
  },
];

const Home = () => {
  return (
    <div>
      <Intro />
      <Examples header={examplesHeader} items={examples} />
      <Features
        features={features}
        tag="Budujemy razem"
        header="Całkowite wsparcie"
        description="Budując własny dom możesz liczyć na naszą pomoc na każdym etapie."
      />
      <Faqs />
      <ContactForm />
      <Location />
      <LocationMap />
    </div>
  );
};

export default Home;
