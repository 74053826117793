import { UserCircleIcon } from "@heroicons/react/24/solid";

const testimonials = [
  {
    body: "Profesjonalizm w każdym calu. Budowa domu przebiegła sprawnie i bez problemów. Polecam!",
    author: {
      name: "Michał",
      handle: "michal",
    },
  },
  {
    body:
      "Budowa domu z firmą Domy Renomy to była przyjemność. " +
      "Każdy etap projektu był dokładnie monitorowany i wykonany z najwyższą starannością. " +
      "Dzięki indywidualnemu podejściu do naszych potrzeb, nasz nowy dom jest nie tylko piękny, " +
      "ale i funkcjonalny. Polecam serdecznie!",
    author: {
      name: "Janina Zielińska",
      handle: "janina.zielinska",
    },
  },
  {
    body:
      "Jestem bardzo zadowolony z usług. " +
      "Wszystkie prace zostały wykonane zgodnie z harmonogramem i na najwyższym poziomie. " +
      "Ekipa była zawsze dostępna, aby odpowiedzieć na nasze pytania i doradzić najlepsze rozwiązania. " +
      "To była dobra decyzja, aby z nimi współpracować.",
    author: {
      name: "Paweł Szymański",
      handle: "pawel.szymanski",
    },
  },
  {
    body:
      "Profesjonalizm, terminowość i jakość - to trzy cechy, które najlepiej opisują firmę Domy Renomy. " +
      "Budowa naszego domu przebiegła sprawnie i bez problemów. Efekt końcowy przerósł nasze oczekiwania, " +
      "a współpraca była czystą przyjemnością. Gorąco polecam!",
    author: {
      name: "Karolina i Jacek",
      handle: "karolina.jacek",
    },
  },
  {
    body: "Świetna współpraca i doskonała jakość wykonania.",
    author: {
      name: "Artur",
      handle: "artur",
    },
  },
];

export default function Reviews() {
  return (
    <div className="bg-white mx-auto max-w-7xl px-6 py-16 sm:px-6 sm:py-24 lg:px-8">
      <div className="max-w-2xl">
        <h2 className="text-base font-semibold leading-8 tracking-tight text-red-600">
          Referencje
        </h2>
        <p className="mt-2 text-2xl font-bold tracking-tight text-gray-900">
          Zobacz co nasi klienci mówią o współpracy
        </p>
      </div>
      <div className="mx-auto mt-16 flow-root max-w-2xl sm:mt-20 lg:mx-0 lg:max-w-none">
        <div className="-mt-8 sm:-mx-4 sm:columns-2 sm:text-[0] lg:columns-3">
          {testimonials.map((testimonial) => (
            <div
              key={testimonial.author.handle}
              className="pt-8 sm:inline-block sm:w-full sm:px-4"
            >
              <figure className="rounded-2xl bg-gray-50 p-8 text-sm leading-6">
                <blockquote className="text-gray-900">
                  <p>{`“${testimonial.body}”`}</p>
                </blockquote>
                <figcaption className="mt-6 flex items-center gap-x-4">
                  <UserCircleIcon className="h-10 w-10 rounded-full bg-gray-50 text-gray-500" />
                  <div>
                    <div className="font-semibold text-gray-900">
                      {testimonial.author.name}
                    </div>
                  </div>
                </figcaption>
              </figure>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
