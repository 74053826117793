export default function ProjectPortfolio(props) {
  const columns = [[], [], []];
  props.images.forEach((image, index) => {
    columns[index % 3].push(image);
  });

  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 pt-6 sm:px-6 sm:pt-14 lg:px-8">
        <div>
          <h2 className="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl">
            {props.header}
          </h2>
          <p className="mt-6 text-base leading-7 text-gray-600">
            {props.subheader}
          </p>
        </div>
        <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
          {columns.map((column, colIndex) => (
            <div key={colIndex} className="grid gap-8">
              {column.map((image) => (
                <div
                  key={image.key}
                  className="overflow-hidden h-auto max-w-full rounded-lg"
                >
                  <img
                    className="w-full h-full object-cover"
                    src={image.url}
                    alt=""
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
