import { createStore, combineReducers } from "redux";
import notificationReducer from "./notification/notification-reducer";

const rootReducer = combineReducers({
  notification: notificationReducer,
});

const store = createStore(rootReducer);

export default store;
