import logo from "assets/images/logo/logo-group.svg";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Dialog } from "@headlessui/react";
import { useState } from "react";
import NavbarExpItem from "components/navbar/NavbarExpItem";
import NavbarItem from "components/navbar/NavbarItem";
import { Link, useLocation } from "react-router-dom";

const navigation = [
  { name: "Start", href: "/" },
  { name: "Kontakt", href: "/kontakt" },
];

const mobileNavigation = [
  { name: "Start", href: "/" },
  { name: "Technologia drewniana", href: "/technologie/drewniana" },
  { name: "Technologia murowana", href: "/technologie/murowana" },
  { name: "Projekty", href: "/projekty" },
  { name: "Realizacje", href: "/realizacje" },
  { name: "O nas", href: "/o-nas" },
  { name: "RODO", href: "/rodo" },
  { name: "Kontakt", href: "/kontakt" },
];

const technologies = {
  primary: {
    name: "Technologie budowy",
    href: "#",
  },
  children: [
    { name: "Drewniana", description: "", href: "/technologie/drewniana" },
    {
      name: "Murowana",
      description: "",
      href: "/technologie/murowana",
    },
  ],
};

const about = {
  primary: {
    name: "O nas",
    href: "/o-nas",
  },
  children: [
    {
      name: "RODO",
      description: "Informacje dotyczące przetwarzania danych osobowych",
      href: "/rodo",
    },
  ],
};

const projects = {
  primary: {
    name: "Projekty",
    href: "/projekty",
  },
  children: [
    {
      name: "Realizacje",
      href: "/realizacje",
    },
  ],
};

export default function Navbar() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();

  return (
    <header className="absolute inset-x-0 top-0 z-50">
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <Link to="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Your Company</span>
            <img className="h-8 w-auto" src={logo} alt="" />
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          <NavbarItem key="Start" item={navigation[0]} />
          <NavbarExpItem name="Technologie budowy" item={technologies} />
          <NavbarExpItem name="Projekty" item={projects} />
          <NavbarExpItem name="O nas" item={about} />
          <NavbarItem key="Kontakt" item={navigation[1]} />
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end"></div>
      </nav>
      <Dialog
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-50" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link to="#" className="-m-1.5 p-1.5">
              <span className="sr-only">DOMYRENOMY</span>
              <img className="h-8 w-auto" src={logo} alt="logo" />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {mobileNavigation.map((item) => (
                    <Link
                        key={item.name}
                        to={item.href}
                        className={`-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 ${location.pathname === item.href ? "text-red-600" : ""}`}
                        onClick={() => setMobileMenuOpen(false)}
                    >
                      {item.name}
                    </Link>
                ))}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
