import Features from "components/features/Features";
import traditionalHouse from "assets/images/technologies/brick/traditional-house.jpg";
import aeratedConcreteHouse from "assets/images/technologies/brick/aerated-concrete-house.jpg";
import silicateBlocksHouse from "assets/images/technologies/brick/silicate-blocks-house.jpg";
import expandedClayBlocksHouse from "assets/images/technologies/brick/expanded-clay-blocks-house.jpg";
import ceramicBlockHouse from "assets/images/technologies/brick/ceramic-block-house.jpg";

const types = [
  {
    name: "Technologia tradycyjna",
    description:
      "W tradycyjnej technologii murowanej używa się cegieł ceramicznych, bloczków betonowych lub kamienia. Ściany budowane są warstwa po warstwie, a elementy łączone są zaprawą murarską.",
    imageSrc: traditionalHouse,
    imageAlt: "Placeholder image.",
  },
  {
    name: "Technologia bloczków komórkowych (gazobeton)",
    description:
      "Gazobeton, czyli bloczki z betonu komórkowego, jest lekkim materiałem budowlanym o bardzo dobrych właściwościach izolacyjnych. Bloczki są łatwe do obróbki, co przyspiesza proces budowy.",
    imageSrc: aeratedConcreteHouse,
    imageAlt: "Placeholder image.",
  },
  {
    name: "Technologia silikatów",
    description:
      "Bloczki silikatowe są wykonane z mieszaniny piasku, wapna i wody, co czyni je bardzo ekologicznym materiałem budowlanym. Charakteryzują się dużą wytrzymałością i dobrą izolacyjnością akustyczną.",
    imageSrc: silicateBlocksHouse,
    imageAlt: "Placeholder image.",
  },
  {
    name: "Technologia keramzytowa",
    description:
      "Keramzyt to materiał budowlany produkowany z gliny wypalanej w wysokiej temperaturze. Bloczki keramzytowe są lekkie, wytrzymałe i mają dobre właściwości izolacyjne.",
    imageSrc: expandedClayBlocksHouse,
    imageAlt: "Placeholder image.",
  },
  {
    name: "Technologia pustaków ceramicznych",
    description:
      "Pustaki ceramiczne to nowoczesna forma tradycyjnej cegły, która dzięki swojej strukturze i dużym wymiarom przyspiesza budowę oraz zapewnia lepszą izolacyjność termiczną i akustyczną.",
    imageSrc: ceramicBlockHouse,
    imageAlt: "Placeholder image.",
  },
];

const features = [
  {
    name: "Trwałość",
    description:
      "Domy murowane są bardzo trwałe i mogą przetrwać wiele pokoleń.",
  },
  {
    name: "Wytrzymałość",
    description:
      "Ściany murowane są odporne na uszkodzenia mechaniczne i działanie sił zewnętrznych.",
  },
  {
    name: "Izolacyjność",
    description:
      "Dobre właściwości izolacyjne, zarówno termiczne, jak i akustyczne.",
  },
  {
    name: "Ognioodporność",
    description:
      "Materiały murowane są niepalne, co zwiększa bezpieczeństwo pożarowe.",
  },
  {
    name: "Mikroklimat",
    description:
      "Murowane ściany dobrze regulują wilgotność i temperaturę wewnątrz budynku.",
  },
];

const BrickTechnologyView = () => {
  return (
    <>
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 pt-6 sm:px-6 sm:pt-14 lg:px-8">
          <div className="mx-auto max-w-2xl lg:max-w-none">
            <div className="max-w-3xl">
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Technologia murowana
              </p>
              <p className="mt-4 text-gray-500">
                Murowana technologia budowy domów jest jedną z najstarszych i
                najbardziej powszechnych metod konstrukcji na świecie. Domy
                murowane charakteryzują się trwałością, wytrzymałością oraz
                dobrą izolacyjnością termiczną i akustyczną. Poniżej
                przedstawiamy główne technologie oraz zalety budowy domów
                murowanych.
              </p>
              <p className="mt-4 text-gray-500">
                Budowa domu murowanego to solidna inwestycja na wiele lat. Wybór
                odpowiednich materiałów i technologii zależy od indywidualnych
                potrzeb, budżetu oraz warunków lokalnych.
              </p>
            </div>

            <div className="mt-10 space-y-16 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-16">
              {types.map((type) => (
                <div
                  key={type.name}
                  className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center lg:gap-x-8"
                >
                  <div className="mt-6 lg:col-span-5 lg:mt-0 xl:col-span-4">
                    <h3 className="text-lg font-medium text-gray-900">
                      {type.name}
                    </h3>
                    <p className="mt-2 text-sm text-gray-500">
                      {type.description}
                    </p>
                  </div>
                  <div className="flex-auto lg:col-span-7 xl:col-span-8">
                    <div className="aspect-h-2 aspect-w-5 overflow-hidden rounded-lg bg-gray-100">
                      <img
                        src={type.imageSrc}
                        alt={type.imageAlt}
                        className="object-cover object-center"
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Features
        features={features}
        tag="Kluczowe punkty"
        header="Zalety budowy domu w technologii murowanej"
        description=""
      />
    </>
  );
};

export default BrickTechnologyView;
